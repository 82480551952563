import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AnyModelStr } from '../types/any-models.types';
import { Coach } from '../types/coach.types';
import { EmailLog } from '../types/email-log.types';
import { Mentee } from '../types/mentee.types';
import { Mentor } from '../types/mentor.types';
import { RequireOne } from '../utils';
import { NotificationService } from './notification.service';
import { Lead } from '../types/lead.types';
import { firstValueFrom } from 'rxjs';

export interface IEmailLogServiceParams {
  returnAll?: boolean;
  submissionId?: string;
  event?: LazyLoadEvent;
}
@Injectable({
  providedIn: 'root',
})
export class EmailLogService {
  constructor(private http: HttpClient, private notificationService: NotificationService) {}

  public getEmailLogs(params: HttpParams) {
    return this.http.get(environment.apiUrl + '/email-logs/', { params: params });
  }

  public getEmailLogsForSubmission(params: HttpParams, submission_id: string) {
    return this.http.get(environment.apiUrl + `/email-logs/submission/${submission_id}/`, { params: params });
  }

  public sendEmail(emailId: string) {
    return this.http.get<{ message_id: string; email_log: EmailLog }>(environment.apiUrl + '/send_email/' + emailId + '/');
  }

  public updateEmailLog(updatedEmailLog: RequireOne<EmailLog, 'id'>) {
    return this.http.post<EmailLog>(environment.apiUrl + '/email_log/update/' + updatedEmailLog.id + '/', {
      updated_email_log: updatedEmailLog,
    });
  }

  public newEmailLogs(mr_id: string, email_type_ids: number[], default_model: AnyModelStr, related_submission_id?: string) {
    return this.http.post<EmailLog[]>(environment.apiUrl + '/email_log/new/', {
      mr_id,
      email_type_ids,
      default_model,
      related_submission_id,
    });
  }

  async getEmailLogsByStatus(
    submission_id: string,
    status_id: number,
    type: string,
    programme_id: number = null,
    object: Mentee | Mentor | Coach | Lead
  ): Promise<{ emails: EmailLog[]; required: [] }> {
    this.notificationService.loading = true;
    try {
      const res = await firstValueFrom(
        this.http.post<{ emails: EmailLog[]; required: [] }>(environment.apiUrl + '/email_log/get_logs_by_status/' + submission_id + '/', {
          type,
          programme_id,
          status_id,
          object,
        })
      );
      return res;
    } catch (err) {
      throw new Error(`Can't load emails for status: ${status_id}, programme: ${programme_id}`);
    } finally {
      this.notificationService.loading = false;
    }
  }

  async getEmailLogsByLeadStatus(object: Lead, submission_id: string): Promise<{ emails: EmailLog[]; required: [] }> {
    this.notificationService.loading = true;
    try {
      const res = await firstValueFrom(
        this.http.post<{ emails: EmailLog[]; required: [] }>(environment.apiUrl + '/email_log/get_logs_by_lead_status/', {
          object,
          submission_id,
        })
      );
      return res;
    } catch (err) {
      throw new Error(`Can't load emails for status change: ${object.status_pre}, ${object.status_post}`);
    } finally {
      this.notificationService.loading = false;
    }
  }

  async getEmailLogsForMr(mr): Promise<{ emails: EmailLog[]; required: [] }> {
    this.notificationService.loading = true;
    try {
      const res = await firstValueFrom(
        this.http.post<{ emails: EmailLog[]; required: [] }>(environment.apiUrl + '/email_log/get_logs_for_mr/', {
          ...mr,
        })
      );
      return res;
    } catch (err) {
      throw new Error(`Can't load emails for status: ${mr.status_id}.`);
    } finally {
      this.notificationService.loading = false;
    }
  }

  prerender_email(mr_id: string, email_type_id: number, default_model: AnyModelStr) {
    return this.http.post<string>(environment.apiUrl + '/prerender_email/', {
      mr_id,
      email_type_id,
      default_model,
    });
  }
}
