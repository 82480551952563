import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { ConfirmationService, FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { Avatar } from 'primeng/avatar';
import { lastValueFrom, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { UpdateAnswer } from 'src/app/core/actions/answer.actions';
import { BadgesLoad } from 'src/app/core/actions/badges.actions';
import { CitiesLoad } from 'src/app/core/actions/cities.actions';
import { UpdateCoach } from 'src/app/core/actions/coach.actions';
import { CountriesLoad } from 'src/app/core/actions/countries.actions';
import { EnvironmentsLoad } from 'src/app/core/actions/environments.actions';
import { FeedbackStatusesLoad } from 'src/app/core/actions/feedback-status.actions';
import { FieldsLoad } from 'src/app/core/actions/fields.actions';
import { GenderLoad } from 'src/app/core/actions/gender.actions';
import { LanguagesLoad } from 'src/app/core/actions/languages.actions';
import { UpdateMentee } from 'src/app/core/actions/mentee.actions';
import { UpdateMentor } from 'src/app/core/actions/mentor.actions';
import { MrStatusesLoad } from 'src/app/core/actions/mr-statuses.actions';
import { PersonalAttributesLoad } from 'src/app/core/actions/personal-attributes.actions';
import { ProgrammesLoad } from 'src/app/core/actions/programmes.actions';
import { PromoCodesLoad } from 'src/app/core/actions/promo-codes.actions';
import { SkillsLoad } from 'src/app/core/actions/skills.actions';
import { StatusesLoad } from 'src/app/core/actions/statuses.actions';
import { DecisionLeadStatusesLoad, PostLeadStatusesLoad, PreLeadStatusesLoad } from 'src/app/core/actions/lead-statuses.actions';
import { TeamSizesLoad } from 'src/app/core/actions/team-sizes.actions';
import { UsersLoad } from 'src/app/core/actions/user.actions';
import { AppState } from 'src/app/core/reducers';
import { Answer } from '../types/answer.types';
import { AnyModelStr } from '../types/any-models.types';
import { Coach } from '../types/coach.types';
import { EmailLog } from '../types/email-log.types';
import { Mentee } from '../types/mentee.types';
import { Mentor } from '../types/mentor.types';
import { convertDate2String, hasValue } from '../utils';
import { EmailLogService } from './email-log.service';
import { NotificationService } from './notification.service';
import { UserConversationLoad } from 'src/app/core/actions/conversation.actions';
import { UpdateLead } from 'src/app/core/actions/lead.actions';
import { Lead } from '../types/lead.types';
import { ReminderArrayUpdate, RemindersLoad } from 'src/app/core/actions/reminders.actions';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private store: Store<AppState>,
    private emailLogService: EmailLogService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  loadData2Store() {
    this.store.dispatch(new BadgesLoad());
    this.store.dispatch(new CitiesLoad());
    this.store.dispatch(new CountriesLoad());
    this.store.dispatch(new DecisionLeadStatusesLoad());
    this.store.dispatch(new EnvironmentsLoad());
    this.store.dispatch(new FeedbackStatusesLoad());
    this.store.dispatch(new FieldsLoad());
    this.store.dispatch(new GenderLoad());
    this.store.dispatch(new LanguagesLoad());
    this.store.dispatch(new MrStatusesLoad());
    this.store.dispatch(new PersonalAttributesLoad());
    this.store.dispatch(new PostLeadStatusesLoad());
    this.store.dispatch(new PreLeadStatusesLoad());
    this.store.dispatch(new ProgrammesLoad());
    this.store.dispatch(new PromoCodesLoad());
    this.store.dispatch(RemindersLoad());
    this.store.dispatch(new SkillsLoad());
    this.store.dispatch(new StatusesLoad());
    this.store.dispatch(new TeamSizesLoad());
    this.store.dispatch(new UserConversationLoad());
    this.store.dispatch(new UsersLoad());
  }

  updateRawInstance(type: AnyModelStr, modelId: string, model: any) {
    if (type == 'mentee') {
      this.store.dispatch(new UpdateMentee(modelId, model));
    } else if (type === 'mentor') {
      this.store.dispatch(new UpdateMentor(modelId, model));
    } else if (type === 'coach') {
      this.store.dispatch(new UpdateCoach(modelId, model));
    } else if (type === 'lead') {
      this.store.dispatch(new UpdateLead(modelId, model));
    }
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};
    Object.keys(form.controls).forEach(key => {
      let currentControl = form.controls[key];
      if (currentControl.dirty) {
        if (currentControl.controls) dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });
    for (const [key, value] of Object.entries(dirtyValues)) {
      if (value instanceof Date) dirtyValues[key] = convertDate2String(value, true);
    }
    return dirtyValues;
  }

  updateInstance(type: AnyModelStr, form: UntypedFormGroup, updateId: string, dry = false) {
    let model;
    let dirtyValues = this.getDirtyValues(form);
    if (form.valid) {
      if (type == 'mentee') {
        model = this.updateMentee(dirtyValues, updateId, dry);
      } else if (type === 'mentor') {
        model = this.updateMentor(dirtyValues, updateId, dry);
      } else if (type === 'coach') {
        model = this.updateCoach(dirtyValues, updateId, dry);
      } else if (type === 'lead') {
        model = this.updateLead(dirtyValues, updateId, dry);
      }
    }
    if (dry) {
      return model;
    }
    window.scroll(0, 0);
    form.markAsPristine();
  }

  updateMentee(dirtyValues: {}, updateId: string, dry: boolean) {
    const mentee: Partial<Mentee> = {
      ...dirtyValues,
    };
    if (!dry) {
      this.store.dispatch(new UpdateMentee(updateId, mentee));
    }
    return mentee;
  }

  updateMentor(dirtyValues: {}, updateId: string, dry: boolean) {
    const mentor: Partial<Mentor> = {
      ...dirtyValues,
    };
    if (!dry) {
      this.store.dispatch(new UpdateMentor(updateId, mentor));
    }
    return mentor;
  }

  updateCoach(dirtyValues: {}, updateId: string, dry: boolean) {
    const coach: Partial<Coach> = {
      ...dirtyValues,
    };
    if (!dry) {
      this.store.dispatch(new UpdateCoach(updateId, coach));
    }
    return coach;
  }

  updateLead(dirtyValues: {}, updateId: string, dry: boolean) {
    const lead: Partial<Lead> = {
      ...dirtyValues,
    };
    if (!dry) {
      this.store.dispatch(new UpdateLead(updateId, lead));
    }
    return lead;
  }

  public async confirmUpdate(
    event: Event,
    mm$: Observable<Mentee | Mentor | Coach | Lead>,
    type: AnyModelStr,
    form: UntypedFormGroup,
    submissionId: string
  ) {
    const mm = await lastValueFrom(mm$.pipe(filter(hasValue), first()));
    let newEmailLogs: { emails: EmailLog[]; required: [] } = { emails: [], required: [] };

    let new_model = mm;
    if (form.contains('reminders')) {
      // First part: Handle reminders
      const reminderFormArray = form.get('reminders') as FormArray;
      if (reminderFormArray && reminderFormArray.touched) {
        this.handleReminders(reminderFormArray, mm, type);
      }
      // Second part: Proceed after reminders are handled
      form.removeControl('reminders');
      new_model = this.updateInstance(type, form, mm.id, true);
      form.addControl('reminders', reminderFormArray);
    } else {
      new_model = this.updateInstance(type, form, mm.id, true);
    }

    try {
      if (type !== 'lead' && mm.status != undefined && form.value.status != undefined && mm.status !== form.value.status) {
        newEmailLogs = await this.emailLogService.getEmailLogsByStatus(
          submissionId,
          form.value.status,
          type,
          form.value?.programme,
          new_model
        );
      } else if (
        ((mm as Lead).status_pre !== 1 && form.value.status_pre === 1) ||
        ((mm as Lead).status_pre !== 9 && form.value.status_pre === 9) ||
        ((mm as Lead).status_post !== 1 && form.value.status_post === 1)
      ) {
        newEmailLogs = await this.emailLogService.getEmailLogsByLeadStatus(new_model as Lead, submissionId);
      }
    } catch (error) {
      this.notificationService.openSnackBar('error', `${error}`);
      throw error;
    }

    if (newEmailLogs.emails.length > 0) {
      const emailOccurrencesMap = this.countOccurrences(newEmailLogs.emails.map(log => log.email_type));
      const emailString = Object.keys(emailOccurrencesMap).map(key =>
        ` ${key} (${emailOccurrencesMap[key]})`
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/\b\w/g, s => s.toUpperCase())
      );
      if (newEmailLogs.required.length === 0) {
        this.confirmationService.confirm({
          target: event.target,
          message: `Submitting will generate: ${emailString}`,
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.updateInstance(type, form, mm.id);
          },
          reject: () => {
            //reject action
          },
        });
      } else {
        this.confirmationService.confirm({
          target: event.target,
          message: `Cannot submit, please fill in: ${newEmailLogs.required.join(', ')}`,
          icon: 'pi pi-exclamation-triangle',
          rejectVisible: false,
          acceptLabel: 'OK',
        });
      }
    } else if (
      mm.payment_level !== undefined &&
      (mm.payment_level == 'R' || mm.payment_level == 'B2BR') &&
      mm.payment_level !== form.value.payment_level
    ) {
      this.confirmationService.confirm({
        target: event.target,
        message: `Submitting will generate "approval" email, are you sure?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.updateInstance(type, form, mm.id);
        },
        reject: () => {},
      });
    } else {
      this.updateInstance(type, form, mm.id);
    }
  }

  private handleReminders(reminderFormArray: FormArray, mm: Mentee | Mentor | Coach | Lead, type: AnyModelStr) {
    let touchedControls = [];
    reminderFormArray.controls.forEach(control => {
      if (control.touched && control.value['date']) {
        touchedControls.push({
          uuid: control.value['uuid'],
          date: control.value['date'] instanceof Date ? convertDate2String(control.value['date'], false) : control.value['date'],
          note: control.value['note'],
          reminder_for: control.value['reminder_for'],
          resolved: control.value['resolved'],
          object_id: mm.id,
          content_type: type,
        });
      }
    });
    if (touchedControls.length > 0) this.store.dispatch(ReminderArrayUpdate({ reminders: touchedControls }));
  }

  countOccurrences = arr => arr.reduce((prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev), {});

  changeValueOfAnswer(answer: Answer) {
    let newValue = document.getElementById('input_' + answer.id).innerText;
    if (newValue == '') {
      newValue = null;
    }
    this.store.dispatch(new UpdateAnswer(answer.id, newValue));
    this.changeStyleState(answer, false);
  }

  changeStyleState(answer: Answer, changed: boolean) {
    const changeElement = document.getElementById('button_' + answer.id) as HTMLButtonElement;
    if (changed) {
      changeElement.disabled = false;
      changeElement.style.display = 'block';
    } else {
      changeElement.disabled = true;
      changeElement.style.display = 'none';
    }
  }

  changedValue(event, answer: Answer) {
    const changed = event.srcElement.value !== answer.value ? true : false;
    this.changeStyleState(answer, changed);
  }

  addShadow(el: Avatar) {
    el.styleClass = el.styleClass + ' p-shadow-3';
  }

  removeShadow(el: Avatar) {
    el.styleClass = 'p-shadow-1';
  }

  setClipboardText(text: string) {
    var id = 'clipboard-textarea';
    var textarea = document.createElement('textarea') as HTMLTextAreaElement;
    textarea.id = id;
    document.querySelector('body').appendChild(textarea);
    var existsTextarea = document.getElementById(id) as HTMLTextAreaElement;
    existsTextarea.value = text;
    existsTextarea.select();
    existsTextarea.setSelectionRange(0, 99999); /* For mobile devices */

    try {
      var status = document.execCommand('copy');
      if (!status) {
        this.notificationService.openSnackBar('error', "Can't copy to clipboard.");
      } else {
        this.notificationService.openSnackBar('success', 'Value is in clipboard.');
      }
    } catch (err) {
      this.notificationService.openSnackBar('error', "Can't copy to clipboard.");
    } finally {
      existsTextarea.remove();
    }
  }

  syncLocalStorage(key, data, secOffset = 2) {
    const localInstances = JSON.parse(localStorage.getItem(key));
    if (localInstances === null || parseInt(((Date.now() - localInstances['savedAt']) / 1000).toString()) > secOffset) {
      localStorage.setItem(key, JSON.stringify({ savedAt: Date.now(), data: data }));
    }
  }

  isTimestampYoungerThan(savedAt, secOffset = 300) {
    return parseInt(((Date.now() - savedAt) / 1000).toString()) < secOffset;
  }

  goToDetails(detailType: string, applicationId: string) {
    const link = `/detail/${detailType}/${applicationId}`;
    this.router.navigate([]).then(result => {
      window.open(link, '_blank');
    });
  }

  private filterMapping(colName: string) {
    const filterConversionMap = {
      mentoring_fields: 'mentoring_fields__id',
      skills: 'skills__id',
      personal_attributes: 'personal_attributes__id',
      preferred_languages: 'preferred_languages__id',
      city: 'city__id',
      country: 'country__id',
      environment: 'environment__id',
      team_size: 'team_size__id',
      gender: 'gender__id',
      programme: 'programme__id',
      program_manager: 'program_manager__id',
      current_field: 'current_field__id',
      'last_mr.finish_date': 'last_mr__finish_date',
      'last_mr.paid': 'last_mr__paid__exact',
      'last_mr.chemistry': 'last_mr__chemistry',
      'last_mr.midterm': 'last_mr__midterm',
      'last_mr.final': 'last_mr__final',
      'last_mr.program_rating': 'last_mr__program_rating',
    };
    let mappedColName = colName;
    if (colName in filterConversionMap) {
      mappedColName = filterConversionMap[colName];
    }
    return mappedColName;
  }

  public getParamTuple(colName: string, filter: FilterMetadata) {
    if (filter.matchMode == DjangoFiltersModes.EXACT && !['last_mr.finish_date', 'last_tier_move'].includes(colName)) {
      filter.matchMode = '';
    }
    return { name: `${this.filterMapping(colName)}${filter.matchMode}`, value: filter.value };
  }

  public paramsFromEvent(event: LazyLoadEvent): HttpParams {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('offset', event.first);
    httpParams = httpParams.append('limit', event.rows);
    if (event.globalFilter) {
      httpParams = httpParams.append('search', event.globalFilter);
    }
    if (event.sortField && event.sortOrder) {
      const sortOrderPrefix = event.sortOrder == 1 ? '' : '-';
      httpParams = httpParams.append('ordering', sortOrderPrefix + event.sortField);
    }
    if (event.filters) {
      Object.entries(event.filters).forEach(([colName, filter]) => {
        if (Array.isArray(filter) && filter[0].value != null) {
          filter.forEach(element => {
            const { name, value } = this.getParamTuple(colName, element as FilterMetadata);
            const vv = value as Date;
            if (['last_mr.finish_date', 'last_tier_move', 'open_from'].includes(colName)) {
              httpParams = httpParams.append(name, value ? convertDate2String(value) : value);
            } else if (vv) {
              httpParams = httpParams.append(name, moment(vv).utcOffset(0, true).format());
            }
          });
        } else if (filter.value !== null && filter.value !== '' && filter.value !== undefined) {
          const { name, value } = this.getParamTuple(colName, filter);
          httpParams = httpParams.append(name, value);
        }
      });
    }
    return httpParams;
  }

  clear_columns_selection(sessionKeyCol, default_cols) {
    window.localStorage.removeItem(sessionKeyCol);
    return default_cols;
  }
}

export class DjangoFiltersModes {
  static readonly EXACT = '__exact';
  static readonly UNACCENT_CONTAINS = '__unaccent__icontains';
  static readonly CONTAINS = '__contains';
  static readonly IN = '__in';
  static readonly IN_AND = '__in_AND';
  static readonly GTE = '__gte';
  static readonly LTE = '__lte';
  static readonly NOT = '__not_in';
}
